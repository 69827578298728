import React, { useState, useRef, useEffect } from 'react';

export default function LoadingMessage() {
  const [show, setShow] = useState(false);
  const timeoutRef = useRef();

  useEffect(() => {
    timeoutRef.current = setTimeout(setShow.bind(null, true), 300);

    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <>
      { show && <h2>Loading...</h2> }
    </>
  );
}
