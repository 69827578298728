import { useContext } from 'react';

import { StoreContext } from '../store';
import API_BASE_URL from '../constants/api';
import { LOG_ACTIVE } from '../constants/actions';
import urlWithQuery from '../util/http';

type CustomHeaders = { [key: string]: string }

const defaultHeaders = (token: string): CustomHeaders => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
});

const RequestMethod = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
};


type RequestOptions = {
  method?: string;
  data?: { [key: string]: string | number };
  headers?: CustomHeaders;
  signal?: AbortSignal;
}

interface IUseRequest {
  request: (path: string, requestOptions: RequestOptions) => Promise<Response>;
  requestMethod: {[key:string]:string};
}

const useRequest = (): IUseRequest => {
  const { state, dispatch } = useContext(StoreContext);

  const accessToken = (state && state.user && state.user.signInUserSession)
    ? state.user.signInUserSession.accessToken.jwtToken
    : '';

  const request = (
    path: string,
    {
      method = RequestMethod.GET, data = {}, headers = {}, signal,
    }: RequestOptions = {},
  ): Promise<Response> => {
    let url = `${API_BASE_URL}${path}`;

    const requestOptions: RequestInit = { // eslint-disable-line no-undef
      method,
      headers: { ...defaultHeaders(accessToken), ...headers },
      mode: 'cors',
      credentials: 'include',
    };

    if (signal) { requestOptions.signal = signal; }

    if (method === RequestMethod.GET) {
      url = urlWithQuery(url, data).toString();
    } else {
      requestOptions.body = JSON.stringify(data);
    }

    if (dispatch) {
      dispatch({ type: LOG_ACTIVE, payload: { time: Date.now() } });
    }

    return fetch(url, requestOptions);
  };

  return { request, requestMethod: RequestMethod };
};

export default useRequest;
