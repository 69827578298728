import React, { useContext } from 'react';
import { Dialog, CardActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useDeadlineExpired from './hooks/useDeadlineExpired';
import { StoreContext } from './store';
import { LOG_ACTIVE } from './constants/actions';

import { INACTIVE_WARNING_TIME } from './constants/autologout';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const InActivityWarning = () => {
  const classes = useStyles();
  const { dispatch, state } = useContext(StoreContext);
  const lastActiveAt = (state && state.lastActive.time) || 1;
  const almostInactive = useDeadlineExpired(lastActiveAt + INACTIVE_WARNING_TIME);

  return (
    <Dialog
      open={almostInactive}
      onClick={() => dispatch({ type: LOG_ACTIVE, payload: { time: Date.now() } })}
    >
      <CardActions className={classes.buttons}>
        <Button className={classes.button} variant="contained" color="primary">
          Your session will expire soon. Click here to stay logged in.
        </Button>
      </CardActions>
    </Dialog>
  );
};

export default InActivityWarning;
