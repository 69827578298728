import { createTheme } from '@material-ui/core/styles';

const primary = {
  main: '#008489',
};

const secondary = {
  main: '#fc5b1f',
};

const background = {
  default: '#fff',
  paper: '#fff',
};

const hint = '#6f6f6f';

const theme = createTheme({
  palette: {
    primary,
    secondary,
    background,
    text: {
      hint,
      secondary: hint,
    },
  },
  props: {
    innerContainerMaxWidth: 1040,
    innerContainerMargin: '0 auto',
    innerContainerWidth: '100%',
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;
