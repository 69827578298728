const cognito = {
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    redirectSignIn: process.env.REACT_APP_CALLBACK_URL || window.location.origin,
    redirectSignOut: process.env.REACT_APP_CALLBACK_URL || window.location.origin,
    responseType: 'code',
    scope: ['email', 'openid'], // FIXME: add 'profile' to the scope when available
  },
};

export default cognito;
