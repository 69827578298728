import { createContext } from 'react';
import {
  SIGN_IN, SIGN_OUT, UPDATE_DATEPICKER, LOG_ACTIVE,
} from './constants/actions';

export const initialState = {
  user: null,
  datePicker: {
    toDate: null,
    fromDate: null,
  },
  lastActive: {
    time: 0,
  },
};

type StateType = {
  user: any;
  datePicker: {
    fromDate: Date | null;
    toDate: Date | null;
  };
  lastActive: {
    time: Number;
  };
}

export const persistanceConfig = {
  namespace: 'dataPortal',
  stateKeys: ['lastActive'],
};

export const reducer = (state: StateType, action: any): StateType => {
  // console.log(action); // Uncomment for debug logs

  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        user: null,
      };
    case UPDATE_DATEPICKER:
      return {
        ...state,
        datePicker: {
          ...state.datePicker,
          [action.payload.fieldName]: action.payload.date,
        },
      };
    case LOG_ACTIVE:
      return {
        ...state,
        lastActive: {
          time: action.payload.time,
        },
      };
    default:
      throw new Error(`unhandled action ${action.type}`);
  }
};

type StoreContextType = {
  state: StateType;
  dispatch: (action: any) => void;
}

const emptyContext: StoreContextType = { state: initialState, dispatch: () => {} };

export const StoreContext = createContext(emptyContext);
