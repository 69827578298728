import React from 'react';

import ActionButton from './ActionButton';

import { useSessionManagement } from '../hooks/useSession';

const LogoutButton = () => {
  const [, withSignOut] = useSessionManagement();

  const LogoutButtonWithSignOut = withSignOut(ActionButton);

  return <LogoutButtonWithSignOut label="logout" />;
};

export default LogoutButton;
