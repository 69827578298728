import { useState, useEffect, useContext } from 'react';
import { Hub } from 'aws-amplify';

import { LOG_ACTIVE, SIGN_IN } from '../constants/actions';
import { StoreContext } from '../store';

const useAuthorize = () => {
  const { dispatch } = useContext(StoreContext);
  const [error, setError] = useState('');

  useEffect(() => {
    if (! dispatch) { return () => {}; }

    const handleAuthEvent = async ({ event, data }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          dispatch({ type: SIGN_IN, payload: { user: data } });
          dispatch({ type: LOG_ACTIVE, payload: { time: Date.now() } });
          setError('');
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
        case 'customState_failure':
          setError(`Sign In Failure: ${data.message}`);
          break;
        case 'signOut':
          setError('');
          break;
        default:
          console.warn(event); // eslint-disable-line no-console
          setError('Unsupported auth event');
      }
    };

    const listen = () => Hub.listen('auth', (data) => handleAuthEvent(data.payload));
    const closeListener = () => { Hub.remove('auth'); };

    listen();

    return closeListener;
  }, [dispatch]);

  return error;
};

export default useAuthorize;
