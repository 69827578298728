import { useState } from 'react';
import { format as formatDate } from 'date-fns';

import API_BASE_URL from '../constants/api';
import DATA_SETS, { API_DATE_FORMAT } from '../constants/dataSets';
import useRequest from './useRequest';
import urlWithQuery from '../util/http';


const useDataSetDownloadUrlString = (dataSet, dateRange) => {
  if (! dataSet || ! dateRange.from || ! dateRange.to) {
    return ['', ''];
  }

  const { action, datePrecision } = DATA_SETS[dataSet] || {};

  if (! action) {
    return ['', 'Please choose correct Data Set'];
  }

  const params = {
    create_date_from: formatDate(dateRange.from, API_DATE_FORMAT[datePrecision]),
    create_date_to: formatDate(dateRange.to, API_DATE_FORMAT[datePrecision]),
  };
  const downloadUrl = urlWithQuery(`${API_BASE_URL}/api/${dataSet}/${action}.csv`, params);

  return [downloadUrl.toString(), ''];
};

const useAuthorizedDataSetDownloadAction = (dataSet, downloadUrlString) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();

  const actionFunc = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await request(`/api/${dataSet}/authorize`);

      if (response.status !== 200) {
        try {
          const { error: responseError } = await response.json();

          setError(responseError);
        } catch {
          setError(response.statusText);
        }
        setLoading(false);
      } else {
        setTimeout(() => setLoading(false), 1000);
        window.location.assign(downloadUrlString);
      }
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  return [
    actionFunc, loading, error,
  ];
};

/*
 * Responsible for knowing what the API needs to be able to give us a data set.
 *   It tells the parent if it has that ready.
 */
const useDataSetDownload = (dataSet, dateRange) => {
  const [downloadUrlString, downloadUrlUnprocessableError] = useDataSetDownloadUrlString(dataSet, dateRange);
  const [onDownloadAction, loading, downloadActionError] = useAuthorizedDataSetDownloadAction(
    dataSet, downloadUrlString,
  );

  const downloadState = {
    canRequest: downloadUrlString.length > 0,
    error: downloadUrlUnprocessableError || downloadActionError,
    loading,
  };

  return {
    downloadState, onDownloadAction,
  };
};

export default useDataSetDownload;
