import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';

import { Typography, Grid, Box } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { initialFocusedDateFrom } from '../util/dataDates';
import useDatepicker from '../hooks/useDatepicker';

import { DAY, MONTH, YEAR } from '../constants/dataSets';

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  picker: {
    width: '100%',
  },
}));

const datepickerPrecisionProps = {
  [DAY]: {
    views: ['date'],
    format: 'dd MMM yyyy',
  },
  [MONTH]: {
    views: ['year', 'month'],
    format: 'MMM yyyy',
  },
  [YEAR]: {
    views: ['year'],
    format: 'yyyy',
  },
};

const useDateRangeForPrecision = (precision, setRange) => {
  // We want the date values for a given precision to be independent but to
  // persist when dataSet type is changed or a date of the other precision is
  // selected.
  //
  // Do we want these values persisted across reloads?
  // Not yet, but it would be pretty easy with usePersistentReducer.
  const [fromDay, setFromDay] = useDatepicker('fromDay');
  const [toDay, setToDay] = useDatepicker('toDay');
  const [fromMonth, setFromMonth] = useDatepicker('fromMonth');
  const [toMonth, setToMonth] = useDatepicker('toMonth');
  const [fromYear, setFromYear] = useDatepicker('fromYear');
  const [toYear, setToYear] = useDatepicker('toYear');

  const fromValues = { [DAY]: fromDay, [MONTH]: fromMonth, [YEAR]: fromYear };
  const toValues = { [DAY]: toDay, [MONTH]: toMonth, [YEAR]: toYear };
  const fromSetters = { [DAY]: setFromDay, [MONTH]: setFromMonth, [YEAR]: setFromYear };
  const toSetters = { [DAY]: setToDay, [MONTH]: setToMonth, [YEAR]: setToYear };

  const fromValue = fromValues[precision];
  const toValue = toValues[precision];

  useEffect(() => {
    setRange({
      from: fromValue,
      to: toValue,
    });
  }, [precision, fromValue, toValue, setRange]);

  return [fromSetters[precision], toSetters[precision]];
};

const DateRangeSelector = ({
  range, setRange, precision, minDate, maxDate,
}) => {
  const [setFromDate, setToDate] = useDateRangeForPrecision(precision, setRange);
  const classes = useStyles();

  const minFromDate = minDate;
  const maxFromDate = range.to || maxDate;
  const minToDate = range.from || minDate;
  const maxToDate = maxDate;
  const initialDateFrom = initialFocusedDateFrom(precision);
  const initialDateTo = maxDate;

  const { views, format } = datepickerPrecisionProps[precision];

  return (
    <>
      <Typography variant="h5" gutterBottom className={classes.boldText}>
        Select a date range
      </Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              className={classes.picker}
              views={views}
              data-testid="from-date-picker"
              disableToolbar
              autoOk
              disableFuture
              variant="inline"
              inputVariant="outlined"
              format={format}
              id="date-picker-from"
              aria-label="From date"
              label="From"
              initialFocusedDate={initialDateFrom}
              value={range.from}
              minDate={minFromDate}
              maxDate={maxFromDate}
              onChange={setFromDate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              className={classes.picker}
              views={views}
              data-testid="to-date-picker"
              disableToolbar
              autoOk
              disableFuture
              variant="inline"
              inputVariant="outlined"
              format={format}
              id="date-picker-to"
              label="To"
              aria-label="To date"
              initialFocusedDate={initialDateTo}
              value={range.to}
              minDate={minToDate}
              maxDate={maxToDate}
              onChange={setToDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Box color="text.hint">
              All dates are inclusive.
            </Box>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};

DateRangeSelector.propTypes = {
  range: PropTypes.exact({ from: PropTypes.instanceOf(Date), to: PropTypes.instanceOf(Date) }).isRequired,
  setRange: PropTypes.func.isRequired,
  precision: PropTypes.oneOf([DAY, MONTH, YEAR]).isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
};

export default DateRangeSelector;
