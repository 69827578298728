import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Navigation from './Navigation';
import LogoutButton from '../components/LogoutButton';

import { ReactComponent as DataPortalLogo } from '../images/SBDataPortal.svg';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    borderBottom: 'solid 1px #ccc',
  },
  toolBar: {
    maxWidth: theme.props.innerContainerMaxWidth,
    margin: theme.props.innerContainerMargin,
    width: theme.props.innerContainerWidth,
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <div className={classes.title}>
          <DataPortalLogo title="Simply Business - Data Portal" aria-label="Simply Business - Data Portal" />
        </div>
        <LogoutButton />
      </Toolbar>
      <Navigation />
    </AppBar>
  );
};

export default Header;
