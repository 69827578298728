import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  Typography, Container,
} from '@material-ui/core';

import { StoreContext } from '../store';
import dataPortalLogo from '../images/SBDataPortal.svg';
import LoginButton from '../components/LoginButton';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(18, 0, 6),
    flexGrow: 1,
  },
  heroIntro: {
    padding: theme.spacing(6, 0, 6),
  },
}));

const StyledLoginButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 10, 2, 10),
    width: '100%',
  },
}))(LoginButton);

export default function GuestPage() {
  const { state } = useContext(StoreContext);
  const classes = useStyles();

  const { user } = state;

  return (
    <>
      {! user && (
        <Container maxWidth="sm" className={classes.heroContent}>
          <img
            src={dataPortalLogo}
            className="data-portal-logo"
            alt="Simply Business - Data Portal"
            aria-label="Simply Business - Data Portal"
            width="100%"
          />

          <Typography variant="h5" align="center" color="textSecondary" paragraph className={classes.heroIntro}>
            Welcome, please login below.
          </Typography>
          <StyledLoginButton />
        </Container>
      )}
    </>
  );
}
