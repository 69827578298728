import { useReducer, useEffect } from 'react';


export const namespacedKey = (namespace, key) => namespace.concat('::', key);
export const sentinelKey = (namespace) => namespacedKey(
  namespace,
  'long_string_showing_namespace_is_here_even_without_keys48639785281',
);
export const SENTINEL_VALUE = 'PRESENT';

// These super-thin functions are here to make the map().filter().filter().map() chain readable.
// They would be silly in any other context.
const exclude = (keysToExclude) => (([key]) => (! keysToExclude.includes(key)));
const within = (namespace) => ({
  keyToKeyValueFromLocalStorage: ((key) => [key, window.localStorage.getItem(namespacedKey(namespace, key))]),
});
const excludeValueIsNull = ([, val]) => (val !== null);
const excludeValueIsUndefined = ([, val]) => (typeof val !== 'undefined');
const KeyJSONParsedValue = ([key, val]) => [key, JSON.parse(val)];

export const withOverWritingFromLocalStorage = (namespace, keysToLoad, originalReducerInit = (arg) => arg) => {
  const realUseReducerInit = (initialArg) => {
    if (window.localStorage.getItem(sentinelKey(namespace))) {
      const entriesFromDefault = Object.entries(originalReducerInit(initialArg))
        .filter(exclude(keysToLoad));

      const entriesFromLocalStorage = keysToLoad
        .map(within(namespace).keyToKeyValueFromLocalStorage)
        .filter(excludeValueIsNull)
        .filter(excludeValueIsUndefined)
        .map(KeyJSONParsedValue);

      return Object.fromEntries([
        ...entriesFromDefault,
        ...entriesFromLocalStorage,
      ]);
    }

    return originalReducerInit(initialArg);
  };

  return realUseReducerInit;
};

export const usePersistentReducer = (reducer, initialArg, persist, init = (arg) => arg) => {
  const { namespace, stateKeys: keysToSave } = persist;
  const initThenLoad = withOverWritingFromLocalStorage(namespace, keysToSave, init);

  const [state, dispatch] = useReducer(reducer, initialArg, initThenLoad);

  const valuesToSave = [...keysToSave.map((key) => JSON.stringify(state[key]))];

  useEffect(
    () => {
      keysToSave.forEach((key, i) => {
        if (typeof valuesToSave[i] === 'undefined') {
          window.localStorage.removeItem(namespacedKey(namespace, key));
        } else {
          window.localStorage.setItem(namespacedKey(namespace, key), valuesToSave[i]);
        }
      });
      window.localStorage.setItem(sentinelKey(namespace), SENTINEL_VALUE);
      // Should we remove the keys when the reducer unmounts?
      // we don't need to for Data Portal. When we do,
      // write tests for it and uncomment this cleanup function:
      // return () => window.localStorage.removeItem(sentinel(namespace));
    },
    // Only save a value if it actually changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [namespace, ...keysToSave, ...valuesToSave],
  );

  return [state, dispatch];
};
