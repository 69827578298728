import { LOSS_RATIO_START_DATE, POLICIES_DATA_START_DATE, CHOPIN_RFQS_DATA_START_DATE } from './dates';


/*
Responsible for canonically representing
differences among how data sets are queried.
*/
export const YEAR = 'year';
export const MONTH = 'month';
export const DAY = 'day';

export const API_DATE_FORMAT = {
  [DAY]: 'yyyy-MM-dd',
  [MONTH]: 'yyyy-MM',
  [YEAR]: 'yyyy',
};

export const AGGREGATED = 'aggregated';
export const UNAGGREGATED = 'unaggregated';

const DATA_SETS = {
  quotes: {
    label: 'Quotes',
    action: AGGREGATED,
    datePrecision: DAY,
    startDate: CHOPIN_RFQS_DATA_START_DATE,
  },
  policies: {
    label: 'Policies',
    action: UNAGGREGATED,
    datePrecision: DAY,
    startDate: CHOPIN_RFQS_DATA_START_DATE,
  },
  cancellations: {
    label: 'Cancellations',
    action: UNAGGREGATED,
    datePrecision: DAY,
    startDate: CHOPIN_RFQS_DATA_START_DATE,
  },
  panel_share: {
    label: 'Panel Share',
    action: AGGREGATED,
    datePrecision: MONTH,
    startDate: POLICIES_DATA_START_DATE,
  },
};

const ON_INTEGRATION = process.env.REACT_APP_STAGE === 'integration';
const ON_LOCAL = process.env.REACT_APP_STAGE === 'local';

// const ON_TEST = process.env.REACT_APP_STAGE === 'test';
// const ON_STAGING = process.env.REACT_APP_STAGE === 'staging'
// const ON_PRODUCTION = process.env.REACT_APP_STAGE === 'production'

if (ON_INTEGRATION || ON_LOCAL) {
  Object.assign(DATA_SETS, {
    adjusted_net_loss_ratio: {
      label: 'Adjusted Net Loss Ratio',
      action: AGGREGATED,
      datePrecision: YEAR,
      startDate: LOSS_RATIO_START_DATE,
    },
    net_earned_loss_ratio: {
      label: 'Net Earned Loss Ratio',
      action: AGGREGATED,
      datePrecision: YEAR,
      startDate: LOSS_RATIO_START_DATE,
    },
  });
}

export default DATA_SETS;
