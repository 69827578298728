import React from 'react';
import PropTypes from 'prop-types';

import ActionButton from './ActionButton';

import { useSessionManagement } from '../hooks/useSession';

const LoginButton = ({ classes }) => {
  const [withSignIn] = useSessionManagement();

  const LogInOutButton = withSignIn(ActionButton);

  return <LogInOutButton label="login" variant="contained" classes={classes} />;
};

LoginButton.propTypes = {
  classes: PropTypes.object,
};

LoginButton.defaultProps = {
  classes: {},
};

export default LoginButton;
