import React, { useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { withFederated } from 'aws-amplify-react';

import { StoreContext } from '../store';
import { SIGN_IN, SIGN_OUT } from '../constants/actions';
import { INACTIVE_TIME_ALLOWED } from '../constants/autologout';

import useDeadlineExpired from './useDeadlineExpired';

export const useSessionManagement = () => {
  const { dispatch } = useContext(StoreContext);

  const withSignIn = withFederated;

  const withSignOut = (Component) => (props) => {
    const signOut = async () => {
      await Auth.signOut();
      dispatch({ type: SIGN_OUT });
    };

    const componentWithProps = React.createElement(Component, { ...props, OAuthSignOut: signOut });

    return componentWithProps;
  };

  return [withSignIn, withSignOut];
};

export const useSession = (lastActiveAt, dispatch) => {
  const inactive = useDeadlineExpired(lastActiveAt + INACTIVE_TIME_ALLOWED);

  useEffect(() => {
    let didCancel = false;
    // Prevent race condition:
    // If `inactive` changes while we're awaiting,
    // don't tell sign-in or sign-out to change state based on stale info.
    // https://overreacted.io/a-complete-guide-to-useeffect/#speaking-of-race-conditions

    const getSession = async () => {
      if (inactive) {
        dispatch({ type: SIGN_OUT });
        Auth.signOut();
      } else {
        try {
          const user = await Auth.currentAuthenticatedUser();

          if (! didCancel) {
            dispatch({ type: SIGN_IN, payload: { user } });
          }
        } catch (error) {
          if (! didCancel) {
            // defensive sign out action trigger
            dispatch({ type: SIGN_OUT });
          }
        }
      }
    };

    getSession();

    return () => { didCancel = true; };
  }, [dispatch, inactive]);
};
