export default function urlWithQuery(
  url: URL | string, params: { [key: string]: any }, baseUrl = window.location.href,
): URL {
  const clonedUrl = new URL(url.toString(), baseUrl);

  Object.keys(params).forEach((key) => {
    clonedUrl.searchParams.append(key, params[key].toString());
  });

  return clonedUrl;
}
