import { useContext } from 'react';

import { StoreContext } from '../store';
import { UPDATE_DATEPICKER } from '../constants/actions';

const useDatepicker = (fieldName) => {
  const { state, dispatch } = useContext(StoreContext);
  const { datePicker = {} } = state;

  const setDate = (date) => {
    dispatch({
      type: UPDATE_DATEPICKER,
      payload: {
        date,
        fieldName,
      },
    });
  };

  return [datePicker[fieldName] || null, setDate];
};

export default useDatepicker;
