import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Tab, Tabs, IconButton, Tooltip,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import HelpDialog from './HelpDialog';
import { StoreContext } from '../store';

import { LOG_ACTIVE } from '../constants/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: theme.props.innerContainerMaxWidth,
    margin: theme.props.innerContainerMargin,
    width: theme.props.innerContainerWidth,
  },

}));

const TabWithBoldActive = withStyles((theme) => ({
  root: {
    '&$selected': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {},
}))((props) => <Tab {...props} />); // eslint-disable-line react/jsx-props-no-spreading

function Navigation({ history }) {
  const classes = useStyles();
  const { dispatch } = useContext(StoreContext);
  const [showHelpDialog, _setShowHelpDialog] = useState(false);
  const setShowHelpDialog = (...args) => {
    dispatch({ type: LOG_ACTIVE, payload: { time: Date.now() } });
    _setShowHelpDialog(...args);
  };

  useEffect(() => history.listen(() => dispatch(
    { type: LOG_ACTIVE, payload: { time: Date.now() } },
  )), [dispatch, history]);

  // TODO: This won't work with route parameters
  const handleCallToRouter = (_e, value) => {
    history.push(value);
  };

  const tabValue = history.location.pathname === '/' ? '/data' : history.location.pathname;

  return (
    <Box className={classes.container}>
      <Tabs
        value={tabValue}
        onChange={handleCallToRouter}
        textColor="inherit"
        indicatorColor="primary"
        className={classes.root}
      >
        <TabWithBoldActive label="Data" value="/data" />
      </Tabs>
      <Tooltip title="Definitions">
        <IconButton color="primary" aria-label="definitions" onClick={() => setShowHelpDialog(true)}>
          <ContactSupportIcon />
        </IconButton>
      </Tooltip>
      <HelpDialog open={showHelpDialog} onClose={() => setShowHelpDialog(false)} />
    </Box>
  );
}

Navigation.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Navigation);
