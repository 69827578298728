import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/object/entries';
import 'core-js/features/object/from-entries';

import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { Auth } from 'aws-amplify';

import App from './App';
import cognito from './constants/cognito';

import './index.css';

Amplify.configure(cognito);
Auth.configure();

const app = React.createElement(App);

ReactDOM.render(app, document.getElementById('root'));
