import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import useAuthorize from './hooks/useAuthorize';
import { StoreContext } from './store';

const Authorizer = ({ children }) => {
  const { state } = useContext(StoreContext);
  const error = useAuthorize();

  if (error) {
    return (
      <Typography component="h1" variant="h3">
        Error:
        {error}
      </Typography>
    );
  }

  if (! state.user) {
    return null;
  }

  return <>{children}</>;
};

Authorizer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Authorizer;
