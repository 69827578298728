import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  common: {
    padding: 10,
  },
  error: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.light,
  },
  warning: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.light,
  },
  success: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.light,
  },
}));

const Alert = ({ severity, children }) => {
  const classes = useStyles();

  const detailedClassName = classes[severity] || classes.error;

  return (
    <Paper
      className={`${classes.common} ${detailedClassName}`}
      elevation={0}
      square={false}
    >
      {children}
    </Paper>
  );
};

Alert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'success']),
};

Alert.defaultProps = {
  severity: 'error',
};

export default Alert;
