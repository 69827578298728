import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  rawDataButton: {
    width: '100%',
  },
});

const RawDataButton = ({ handleClick, disabled, ...testProps }) => {
  const classes = useStyles();

  return (
    <Button
      component="a"
      onClick={handleClick}
      className={`raw-data-button ${classes.rawDataButton}`}
      variant="outlined"
      color="primary"
      size="large"
      aria-label="download"
      disabled={disabled}
      // On production this props should be always empty but it's required for testing to pass data-testid downd
      // data-testid must be passed down because is rendered in the DOM element
      // and RawDataButton is jsut a logical wrapper for the <a> component
      {...testProps} // eslint-disable-line react/jsx-props-no-spreading
    >
      Download
    </Button>
  );
};

RawDataButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

RawDataButton.defaultProps = {
  disabled: false,
};

export default RawDataButton;
