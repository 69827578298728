import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, TextField, MenuItem } from '@material-ui/core';
import DATA_SETS from '../constants/dataSets';


const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const DataSetSelector = ({ dataSet, onChange }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" gutterBottom className={classes.boldText}>
        Select a data set to download
      </Typography>
      <TextField
        style={{ width: '100%' }}
        select
        label="Choose a data set"
        aria-label="Choose a data set"
        value={dataSet}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
      >
        <MenuItem value="">
          <em>Choose a data set</em>
        </MenuItem>
        {Object.entries(DATA_SETS).map(([id, { action, label }]) => (
          <MenuItem key={id} value={id} aria-label={label} data-action={action}>{label}</MenuItem>
        ))}
      </TextField>
    </>
  );
};

DataSetSelector.propTypes = {
  dataSet: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DataSetSelector;
