import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const ActionButton = ({
  label, OAuthSignIn, OAuthSignOut, variant, classes,
}) => (
  <Button
    variant={variant}
    color="primary"
    className={classes.root}
    aria-label={label}
    onClick={OAuthSignIn || OAuthSignOut}
  >
    {label}
  </Button>
);

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['outlined', 'contained']),
  classes: PropTypes.object,
  OAuthSignIn: PropTypes.func,
  OAuthSignOut: PropTypes.func,
};

ActionButton.defaultProps = {
  variant: 'outlined',
  classes: {},
  OAuthSignIn: null,
  OAuthSignOut: null,
};

export default ActionButton;
