import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Paper, CssBaseline, makeStyles } from '@material-ui/core';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import LoadingMessage from './LoadingMessage';
import Header from './layout/Header';
import Authorizer from './Authorizer';
import InActivityWarning from './InActivityWarning';
import {
  StoreContext, initialState, persistanceConfig, reducer,
} from './store';
import { useSession } from './hooks/useSession';
import { usePersistentReducer } from './hooks/usePersistentReducer';
import GuestPage from './pages/GuestPage';
import Footer from './layout/Footer';

import theme from './themes/sb-theme';

import DataPage from './pages/DataPage';

const useStyles = makeStyles(() => ({
  containerBox: {
    maxWidth: theme.props.innerContainerMaxWidth,
    margin: theme.props.innerContainerMargin,
    width: theme.props.innerContainerWidth,
    marginTop: 16,
    padding: 20,
    flexGrow: 1,
  },
}));

export default function App() {
  const classes = useStyles();
  const [state, dispatch] = usePersistentReducer(reducer, initialState, persistanceConfig);

  useSession(state.lastActive.time, dispatch);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Authorizer>
            <Header />
            <Paper className={classes.containerBox} elevation={0} square>
              <Suspense fallback={<LoadingMessage />}>
                <Route path="/data" exact component={DataPage} />
                <Redirect exact from="/" to="data" />
              </Suspense>
            </Paper>
            <InActivityWarning />
          </Authorizer>
          <GuestPage />
          <Footer />
        </Router>
      </ThemeProvider>
    </StoreContext.Provider>
  );
}
