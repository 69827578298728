import React, { useState, useContext, useEffect } from 'react';

import { Grid, CircularProgress, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useDataSetDownload from '../hooks/useDataSetDownload';
import RawDataButton from '../components/RawDataButton';
import DataSetSelector from '../components/DataSetSelector';
import DateRangeSelector from '../components/DateRangeSelector';
import Alert from '../components/Alert';

import { StoreContext } from '../store';
import { LOG_ACTIVE } from '../constants/actions';
import DATA_SETS from '../constants/dataSets';
import { maxAllowedDate } from '../util/dataDates';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));


const DataPage = () => {
  const { dispatch } = useContext(StoreContext);
  const [dataSet, setDataSet] = useState('');
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const { downloadState, onDownloadAction } = useDataSetDownload(dataSet, dateRange);

  const { datePrecision, startDate } = DATA_SETS[dataSet] || {};
  const endDate = maxAllowedDate(datePrecision);

  useEffect(() => {
    dispatch({ type: LOG_ACTIVE, payload: { time: Date.now() } });
  }, [dispatch, dataSet, dateRange.from, dateRange.to]);

  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
      <Grid item xs={12}>
        {downloadState.error && <Alert severity="error">{ downloadState.error }</Alert>}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DataSetSelector dataSet={dataSet} onChange={setDataSet} />
          </Grid>
          {dataSet
          && (
            <Grid item xs={12}>
              <DateRangeSelector
                range={dateRange}
                setRange={setDateRange}
                precision={datePrecision}
                minDate={startDate}
                maxDate={endDate}
              />
            </Grid>
          )}
          {downloadState.canRequest
          && (
            <Grid item xs={12}>
              <RawDataButton
                data-testid="download-raw-data-button"
                handleClick={onDownloadAction}
                disabled={downloadState.loading}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={downloadState.loading}>
        <CircularProgress color="inherit" size={50} />
      </Backdrop>
    </Grid>
  );
};

export default DataPage;
