import { subDays, subMonths } from 'date-fns';

import { DAY, MONTH, YEAR } from '../constants/dataSets';

export const subWithPrecision = (precision, originalDate, subtrahend) => {
  switch (precision) {
    case DAY:
      return subDays(originalDate, subtrahend);
    case MONTH:
      return subMonths(originalDate, subtrahend);
    case YEAR:
      return originalDate;
    default:
      return originalDate;
  }
};

export const maxAllowedDate = (precision) => subWithPrecision(precision, new Date(), 1);
export const initialFocusedDateFrom = (precision) => subWithPrecision(precision, maxAllowedDate(), 6);
