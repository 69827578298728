import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const terms = {
  Segment: "Simply Business 'Segments' reflect the main groups in which verticals "
            + 'are grouped in the Simply Business model. See below table.',
  Vertical: "Simply Business 'Verticals' are the defined products that sit within "
            + 'each Segment  in the Simply Business model. See below table.',
  Lifecycle: 'The lifecycle reflects the transaction type i.e. New Business '
              + '/ Renewal / MTA / Cancellation.',
  'Primary Trade': 'This is the main trade that the customer has selected, '
                   + 'which best represents their trade description.',
  "RFQ's": 'Request for Quotes provides the overall number of quotes requested.',
  Opportunities: 'Opportunities reflects the opportunities where the seleceted '
                 + "insurer has presented a quotation from all requests made for quotations (RFQ's).",
  Converted: 'Number of converted quotes represents the number of quotes to be converted '
             + 'to a live policy for the specified insurer.',
  'Referred Quotes': 'Number of referred quotes represents when an insurer is able to '
                     + 'provide cover but has chosen not to present a quotation.',
  'Declined Quotes': 'The number of declined quotes represents where the selected insurer '
                     + 'does not offer cover and the request for quotation is declined.',
};

const segments = {
  Landlord: ['Commercial Landlord', 'Residential Landlord'],
  Lifestyle: ['Shop', 'Business'],
  Tradesmen: ['Business', 'Professionals'],
  Professionals: ['Business', 'Professionals', 'Shop'],
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  table: {
    marginTop: 20,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
  },
  headCell: {
    color: '#fff',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1rem',
  },
  leadingColumn: {
    minWidth: 150,
  },
  verticalCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  segmentCell: {
    verticalAlign: 'top',
  },
}));

const HelpDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const alphabetically = (a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' });
  const alphabeticallyByFirst = (a, b) => alphabetically(a[0], b[0]);

  return (
    <Dialog onClose={onClose} open={open} aria-labelledby="help-dialog-title">
      <DialogTitle id="help-dialog-title" onClose={onClose} disableTypography>
        <Typography variant="h6">Definitions</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2">
          The way we define data may be different to your own terms,
          so to assist in understanding what you download please find a list
          of common terminology and descriptions.
        </Typography>

        <Table className={classes.table} size="small">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={`${classes.headCell} ${classes.leadingColumn}`}>Term</TableCell>
              <TableCell className={classes.headCell}>Definition</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(terms).sort(alphabeticallyByFirst).map(([term, definition]) => (
              <TableRow key={term}>
                <TableCell component="th" scope="row">
                  {term}
                </TableCell>
                <TableCell>{definition}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Table className={classes.table} size="small">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={`${classes.headCell} ${classes.leadingColumn}`}>Segment</TableCell>
              <TableCell className={classes.headCell}>Vertical</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(segments).sort(alphabeticallyByFirst).map(([segment, verticals]) => (
              <TableRow key={segment}>
                <TableCell className={classes.segmentCell} component="th" scope="row">
                  {segment}
                </TableCell>
                <TableCell className={classes.verticalCell}>
                  {verticals.sort(alphabetically).map((vertical) => (
                    <span key={vertical}>{vertical}</span>
                  ))}
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

HelpDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default HelpDialog;
