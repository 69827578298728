import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#f3f3f3',
    padding: 32,
    justifySelf: 'flex-end',
    alignSelf: 'center',
    maxWidth: theme.props.innerContainerMaxWidth,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '96%',
      fontSize: 12,
    },
  },
  copyrightWrapper: {
    marginRight: 8,
  },
}));

const Footer = () => {
  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.footer}>
      <Grid item xs>
        <Typography color="textSecondary" variant="caption" aria-label="legal">
          Legal&nbsp;-&nbsp;
        </Typography>
        <Typography color="textSecondary" variant="caption">
          <Link color="inherit" aria-label="privacy policy" href="https://www.simplybusiness.co.uk/privacy-policy/">
            Privacy policy
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.copyrightWrapper}>
        <Typography color="textSecondary" variant="caption" aria-label="copyright">
          © Copyright&nbsp;
          {year}
          &nbsp;Simply Business. All Rights Reserved.
          Simply Business is a trading name of Xbridge Limited which is authorised and regulated
          by the Financial Conduct Authority (Financial Services Registration No: 313348).
          Xbridge Limited (No: 3967717) has its registered office at
          6th Floor, 99 Gresham Street, London, EC2V 7NG.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
