import { useEffect, useState } from 'react';
import { MINUTE } from '../constants/autologout';

export default function useDeadlineExpired(deadline, checkEvery = MINUTE) {
  const [expired, setExpired] = useState(Date.now() >= deadline);

  useEffect(() => {
    const checkExpired = () => {
      setExpired(Date.now() >= deadline);
    };

    checkExpired();
    if (! expired) {
      const deadlineCheck = setInterval(
        checkExpired,
        checkEvery,
      );

      return () => clearInterval(deadlineCheck);
    }

    return () => {};
  }, [deadline, checkEvery, expired, setExpired]);

  return expired;
}
